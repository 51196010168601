import _export from "../internals/export";
import _stringPad from "../internals/string-pad";
import _stringPadWebkitBug from "../internals/string-pad-webkit-bug";
var $ = _export;
var $padStart = _stringPad.start;
var WEBKIT_BUG = _stringPadWebkitBug; // `String.prototype.padStart` method
// https://tc39.es/ecma262/#sec-string.prototype.padstart

$({
  target: "String",
  proto: true,
  forced: WEBKIT_BUG
}, {
  padStart: function padStart(maxLength
  /* , fillString = ' ' */
  ) {
    return $padStart(this, maxLength, arguments.length > 1 ? arguments[1] : undefined);
  }
});
export default {};